import { Component } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { EnvService, IRPC, L, MultilingualstringConfigService, RpcError } from '@ic2/ic2-lib';
import { NgbTooltipConfig } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import * as Sentry from '@sentry/angular';
import { AuthGuard, AuthService, ClientEnv, Config, HijiBundle, HijiRight, Ic2ToastrService, TagManager, UpdateService } from 'common';

import { hijiVersion } from 'environments/version';
import { lastValueFrom } from 'rxjs';
import { LocaleLoader } from './LocaleLoader';
@Component({
  selector: 'hiji-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: false,
})
export class AppComponent {
  constructor(
    public irpc: IRPC,
    router: Router,
    authService: AuthService,
    multilingualstringConfigService: MultilingualstringConfigService,
    translate: TranslateService,
    meta: Meta,
    title: Title,
    updateService: UpdateService,
    authGuard: AuthGuard,
    ic2ToastrService: Ic2ToastrService,
    env: EnvService<Config>,
    clientEnv: ClientEnv,
    tagManager: TagManager,
    ngbTooltipConfig: NgbTooltipConfig
  ) {
    Sentry.init({
      dsn: 'https://89c30269de41466ea03f066846d89bf6@o1031950.ingest.sentry.io/5998867',
      integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration({
          useCompression: false,
        }),
      ],
      ignoreErrors: [
        '_avast_submit',
        'msDiscoverChatAvailable',
        'ChunkLoadError: Loading chunk',
        'Failed to fetch dynamically imported module',
        'Lv.Z.getDropdownRule',
        "evaluating 'response.reputation'",
        'Cannot redefine property: googletag',
        'ResizeObserver loop limit exceeded',
        'Large Render Blocking Asset',
        'gCrWeb.edgeTranslate.detectPageState',
        'Service workers are disabled or not supported by this browser',
      ],
      environment: env.configuration.client + '-' + env.configuration.environment,
      debug: !env.configuration.production,
      enabled: env.configuration.production,
      release: hijiVersion.fullHash,

      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: env.configuration.production ? 0.2 : 0,
      replaysSessionSampleRate: 0,
      replaysOnErrorSampleRate: 1.0,
      tracePropagationTargets: [/https:\/\/.*\.hiji\.fr/],
    });

    ngbTooltipConfig.container = 'body';
    ngbTooltipConfig.triggers = 'hover focus click';

    irpc.url = env.configuration.rpcHost + '/rpc';
    irpc.log = env.configuration.logRpcRequests;
    irpc.version = hijiVersion.fullHash;

    irpc.beforeRequestSentHook.subscribe((request) => {
      Sentry.addBreadcrumb({
        category: 'RPC',
        message: request.req.serviceDotMethod,
        level: 'info',
      });
    });

    irpc.defaultOnError = (err) => {
      L.e(err);
      if (err.isBusinessError()) ic2ToastrService.showDanger(translate.instant('global.Une erreur est survenue : {{error}}', { error: err.message }));
      else ic2ToastrService.showDanger(translate.instant('global.Une erreur est survenue'));
    };

    if (localStorage.getItem('redirectUrl') !== null && localStorage.getItem('redirectUrl').startsWith('/update')) {
      console.log('REMOVE UPDATE FROM REDIRECT URL', localStorage.getItem('redirectUrl'));
      localStorage.removeItem('redirectUrl');
    }

    irpc.interceptErrorHandlers.push((req, err) => {
      Sentry.addBreadcrumb({
        category: 'RPC',
        message: req.req.serviceDotMethod,
        data: err,
        level: 'error',
      });
      if (err.code === RpcError.NOT_LOGGED && req.req.serviceDotMethod !== 'CoreService.getBundle' && req.req.serviceDotMethod !== 'CoreService.disconnect') {
        console.log('INTERCEPTED NOT LOGGED ERROR on ' + req.req.serviceDotMethod);
        authService.logout();
        if (!['/sso', '/login'].includes(router.url)) {
          if (env.configuration.hasSSOOtherThanHiji) router.navigate(['/sso']);
          else router.navigate(['/login']);
        }
        return true;
      }
      if (err.isVersionError()) {
        console.log('INTERCEPTED VERSION ERROR on ' + req.req.serviceDotMethod);
        if (!router.url.startsWith('/update')) localStorage.setItem('redirectUrl', router.url);
        router.navigate(['/update/version-error-intercepted-rpc']);
        return true;
      }

      if (err.code === RpcError.HTTP_FAILED) {
        ic2ToastrService.showDanger(
          translate.instant('global.Une erreur est survenue : {{error}}', {
            error: translate.instant(
              "global.Il semblerait que vous n'ayez plus accès à internet ou que le serveur ne réponde plus, veuillez vérifier votre connexion, contactez notre support si le problème persiste"
            ),
          })
        );
        return false;
      } else if (!err.isBusinessError() && authService.userBundle !== null) {
        ic2ToastrService.showDanger(translate.instant('global.Une erreur est survenue'));
      }

      return false;
    });
    if (env.configuration.mailAuthentication) authGuard.redirectLoginRoute = '/mail-auth';
    else if (env.configuration.hasSSOOtherThanHiji) authGuard.redirectLoginRoute = '/sso';
    else authGuard.redirectLoginRoute = '/login';
    authGuard.backoffice = true;

    authService.loginEvent.subscribe((login) => {
      if (!AuthService.has(login.userBundle, HijiRight.ADMIN, HijiRight.GESTIONNAIRE, HijiRight.CONSULTATION_STATS, HijiRight.TRADUCTEUR)) {
        login.permitLogin = false;
        console.log('User has no right to see the backoffice');
        authService.logout(() => {
          router.navigate(['/sso-error/no_right']);
        });
        return;
      }
      Sentry.setUser({
        email: login.userBundle.user.email ?? 'Matricule : ' + (<HijiBundle>login.userBundle.data).hijiUser.matricule,
        id: login.userBundle.user.idUser + '',
        locale: (<HijiBundle>login.userBundle.data).hijiUser.locale,
      });
      if (
        login.userBundle.user.email &&
        login.userBundle.user.email.toLocaleLowerCase().endsWith('@hiji.fr') &&
        !(clientEnv.is(clientEnv.NOUS) || clientEnv.isRecetteEnv() || clientEnv.isDeveloppmentEnv())
      ) {
        localStorage.setItem('hijiStaff', 'true');
      }
      login.onLoginAction = () => {
        const url = new URL(window.location.href);
        let locale = (<HijiBundle>login.userBundle.data).hijiUser.locale;
        if (url.searchParams.get('locale')) locale = url.searchParams.get('locale');
        return Promise.all([
          lastValueFrom(translate.use(locale)).then(() => console.log('translation loaded')),
          LocaleLoader.load(locale).then(() => console.log('locale loaded')),
          tagManager.load().then(() => console.log('tags loaded')),
        ]);
      };
    });

    /*translate.onDefaultLangChange.subscribe((evt) => {
      if (/^en\b/.test(navigator.language) || localStorage.getItem('language') === 'en') translate.use('en');
      else translate.use(translate.defaultLang);
    });*/
    translate.onLangChange.subscribe((evt) => {
      multilingualstringConfigService.currentLanguage = evt.lang;
    });
    translate.setDefaultLang(env.configuration.defaultLanguage);
    const url = new URL(window.location.href);
    const locale = url.searchParams.get('locale');
    if (locale) translate.use(locale);
    else translate.use(env.configuration.defaultLanguage);
    multilingualstringConfigService.availableLanguages = env.configuration.availableLanguages; //attention je m'en sers à d'autres endroits du code
    multilingualstringConfigService.defaultLanguage = env.configuration.defaultLanguage;

    meta.addTags([{ name: 'theme-color', content: env.configuration.themeColor }]);
    title.setTitle(env.configuration.appTitle + ' - Admin');

    updateService.check(env.configuration.production, hijiVersion.fullHash);
  }
}
