import { DOCUMENT } from '@angular/common';
import { AfterViewInit, Component, CSP_NONCE, Inject, OnInit, Renderer2 } from '@angular/core';
import { MeltingSpotJWTService } from 'common';
import { LemonComponent } from '../lemon/lemon.component';

@Component({
    selector: 'hiji-academy',
    templateUrl: './academy.component.html',
    styleUrl: './academy.component.scss',
    standalone: false
})
export class AcademyComponent implements OnInit, AfterViewInit {
  constructor(
    private _renderer2: Renderer2,
    @Inject(DOCUMENT) private _document: Document,
    private meltingSpotJWTService: MeltingSpotJWTService,
    @Inject(CSP_NONCE) private CSP_NONCE: string
  ) {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.meltingSpotJWTService
      .getJWTToken()
      .defaultOnError()
      .execute((token) => {
        let script = this._renderer2.createElement('script');
        script.type = `text/javascript`;
        script.setAttribute('nonce', this.CSP_NONCE);
        script.text = LemonComponent.trustedTypePolicy.createScript(`
          window.MeltingSpot.injectSpotInto('meltingspot-frame', {
            spotId: '4d66fe26-2f61-4cc6-8777-db1e422c7043',
            themeMode: 'auto',
            authToken: '${token}'
          });
        `);
        this._renderer2.appendChild(this._document.body, script);
      });
  }
}
